<template>
  <section class="account panel-section">
    <SearchForm @formInline="formInline" />
    <Table
      :baseData="baseData"
      :tableHeader="tableHeader"
      :currentPage="formSearch.pageNo"
      :total="total"
      :listLoading="listLoading"
      :isShowPagination="true"
      @handleCurrent="handleCurrent"
      @onClickBtn="onClickBtn"
    >
      <el-table-column label="操作" width="90" fixed="right">
        <template slot-scope="scope">
          <div class="opration check">
            <span @click="openSchemeMan(scope.row)">方案管理</span>
          </div>
        </template>
      </el-table-column>
    </Table>

    <BaseInfoDialog :item="accountDialogInfo" />

    <ManageDialog :item="editDialogInfo" @open-confirm-dialog="openConfirmDialog" />

    <ConfirmDialog :item="confirmDialogInfo" @update-page-view="updatePageView" />
    
  </section>
</template>
<script>
import API_ACCOUNT from "@/api/api_account";
//客户管理--客户账户
import SearchForm from "@/components/customer/SearchForm.vue";
import ManageDialog from "@/components/customer/ManageDialog.vue";
import BaseInfoDialog from "@/components/customer/BaseInfoDialog.vue";
import ConfirmDialog from "@/components/customer/ConfirmDialog.vue";

export default {
  name: "Account",
  components: {
    SearchForm,
    ManageDialog,
    BaseInfoDialog,
    ConfirmDialog
  },
  data() {
    return {
      baseData: [],
      total: 0,
      listLoading: true,
      tableHeader: this.tableHeader.account.tableHeader,
      formSearch: {
        pageNo: 1,
        pageSize: 10,
        creditCode: undefined,
        shopName: undefined,
      },
      accountDialogInfo: {
        isShow: false,
      },
      editDialogInfo: {
        isShow: false
      },
      confirmDialogInfo: {
        isShow: false
      }
    };
  },
  methods: {
    handleCurrent(val) {
      this.formSearch.pageNo = val;
      this.getDataList();
    },
    getDataList() {
      API_ACCOUNT.getAccountCustom(this.formSearch)
        .then(({ data }) => {
          if (data.code == 200 && data.data) {
            data.data.records.forEach((item,index)=>{
              item.numid = index+1+(this.formSearch.pageNo-1)*this.formSearch.pageSize
            })
            this.baseData = data.data.records || [];
          } else {
            this.baseData = [];
          }
          this.total = (data.data && data.data.total) || 0;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    formInline(data) {
      this.formSearch.pageNo = 1;
      this.formSearch.creditCode = data.creditCode;
      this.formSearch.shopName = data.name;
      this.getDataList();
    },
    onClickBtn(res) {
      this.accountDialogInfo = Object.assign({
        isShow: true,
        type: "accountCustomer",
      }, res);
    },
    openSchemeMan(res) {
      this.editDialogInfo = Object.assign({
        isShow: true,
        type: "accountCustomer",
      }, res);
    },
    updatePageView() {
      this.editDialogInfo = {
        isShow: false
      }
      this.getDataList();
    },
    openConfirmDialog(res) {
      this.confirmDialogInfo = Object.assign({
        isShow: true,
        type: "accountCustomer"
      }, {
        preChargingModel: this.editDialogInfo.chargingModel,
        preBillingScheme: this.editDialogInfo.billingScheme || '0',
        preExpireDate: this.editDialogInfo.expireDate,
        nextChargingModel: res.chargingModel,
        nextBillingScheme: res.billingScheme,
        nextExpireDate: res.expireDate,
        usableTimes: res.usableTimes,
        usedTimes: res.usedTimes,
        shopId: res.shopId,
      });
    }
  },
  activated() {
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_basePage.scss";
.opration {
  span {
    display: inline-block;
    margin-right: 24px;
  }
}
</style>
