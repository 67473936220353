<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      :before-close="onClickClose"
      :close-on-click-modal="false"
      :title="title"
      :width="width"
      class="panel-dialog"
    >
      <div class="d1">
        <span class="s1">变更前：</span>
        <ul :class="['flex']">
          <li
            v-for="(item, index) in detailList"
            :class="[item.class]"
            :key="`detailList${index}`"
          >
            <div class="label background333a47">{{ item.label }}</div>
            <div class="value">
              <div>{{ item.preVal }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="d2">
        <span class="s1 color337CFF">变更后：</span>
        <ul class="flex">
          <li
            v-for="(item, index) in detailList"
            :class="[item.class]"
            :key="`detailList${index}`"
          >
            <div class="label backgroundDAEAFF">{{ item.label }}</div>
            <div class="value">
              <div>{{ item.nextVal }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer" slot="footer">
        <el-button plain size="small" class="btn" @click="onClickClose"
          >返回</el-button
        >
        <el-button plain size="small" class="btn color_1" @click="submit"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API_ACCOUNT from "@/api/api_account";
import { ACCOUNT_SCHEMA } from "@/util/constant";

export default {
  watch: {
    item(newVal) {
      if (newVal) {
        this.setDialogInfo(newVal);
      }
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isShow: false,
      title: "",
      width: "",
      detailList: [],
    };
  },
  computed: {
    sourceType() {
      return this.item.type;
    },
    schemeType() {
      return ACCOUNT_SCHEMA["type"];
    },
    schemeSpec() {
      return ACCOUNT_SCHEMA["spec"][3];
    },
    schemeExpire() {
      return ACCOUNT_SCHEMA["expire"];
    },
    schemeSpecLimit() {
      return ACCOUNT_SCHEMA["specLimit"];
    },
  },
  methods: {
    submit() {
      if (this.sourceType === "accountCustomer") {
        const params = {
          chargingModel: Number(this.item.nextChargingModel),
          expireDate: Number(this.item.nextExpireDate),
          shopId: this.item.shopId,
        };
        if (this.item.nextChargingModel == 3) {
          params.billingScheme = Number(this.item.nextBillingScheme);
        }
        API_ACCOUNT.setAccountCustom(params, false).then(({ data }) => {
          if (data.code == 200) {
            this.$message({
              message: "更新成功~",
              type: "success",
            });
            this.onClickClose();

            this.$emit("update-page-view", {});
          } else {
            this.$message.error({
              message: data.msg || this.$CommonMsg["networkErr"],
            });
          }
        });
      }
    },
    onClickClose() {
      this.isShow = false;
    },
    setDialogInfo(data) {
      this.isShow = data.isShow;
      if (data.type === "accountCustomer") {
        this.title = "请确认执行如下修改";
        this.width = "560px";
        this.setCustomerInfo(data);
      }
    },
    setCustomerInfo(data) {
      this.detailList = [
        {
          label: "账户方案",
          preVal: this.schemeType[data.preChargingModel],
          nextVal: this.schemeType[data.nextChargingModel],
          class: "flexGrow1",
        },
        {
          label: "方案规格",
          preVal:
            data.preChargingModel == 3
              ? this.schemeSpec[data.preBillingScheme]
              : "-",
          nextVal:
            data.nextChargingModel == 3
              ? this.schemeSpec[data.nextBillingScheme]
              : "-",
          class: "flexGrow2",
        },
        {
          label: "有效期",
          preVal: this.schemeExpire[data.preExpireDate],
          nextVal: this.schemeExpire[data.nextExpireDate],
          class: "flexGrow1",
        },
        {
          label: "账户余额",
          preVal: this.getBalance({
            type: 0,
            chargingModel: data.preChargingModel,
            billingScheme: data.preBillingScheme,
            usableTimes: data.usableTimes,
            usedTimes: data.usedTimes,
          }),
          nextVal: this.getBalance(
            {
              type: 1,
              chargingModel: data.nextChargingModel,
              billingScheme: data.nextBillingScheme,
              usableTimes: data.usableTimes,
              usedTimes: data.usedTimes,
            },
            1
          ),
          class: "flexGrow1",
        },
      ];
    },
    getBalance(data) {
      const chargingModel = data.chargingModel;
      const billingScheme = data.billingScheme;
      const type = data.type;
      const usableTimes = data.usableTimes;
      const usedTimes = data.usedTimes;
      let balance = "";
      if (chargingModel == 2 || billingScheme == 1) {
        balance = "-";
      } else {
        balance = usableTimes;
        // if (type == 0) {
        //   balance = usableTimes;
        // } else if (type == 1) {
        //   balance = (
        //     Number(this.schemeSpecLimit[billingScheme] || 0) -
        //     Number(usedTimes || 0)
        //   ).toLocaleString();
        // }
      }
      return balance;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_baseDialog.scss";

.d2 {
  margin-top: 32px;
}
.color337CFF {
  color: #337cff;
}
.label {
  height: 40px;
  margin-top: 12px;
  background: #f0f2f4;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #333a47;
}
.value {
  border-bottom: 1px solid #e3e5e8;
  height: 48px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
  color: #616875;
}
.background333a47 {
  background: #f0f2f4;
}
.backgroundDAEAFF {
  background: #daeaff;
}
.flexGrow1 {
  flex-grow: 1;
  width: 190px;
  > div {
    padding-left: 16px;
  }
}
.flexGrow2 {
  flex-grow: 2;
  width: 221px;
  > div {
    padding-left: 16px;
  }
  .value {
    > div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      line-height: 20px;
    }
  }
}

.li1 {
  height: 48px;
  border-bottom: 1px solid #e3e5e8;
  label {
    width: 120px;
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333a47;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 96px;
  height: 36px;
  text-align: center;
  color: #333333;
  font-size: 14px;
  background: #fff !important;
}

.color_1 {
  background: rgba(37, 137, 255, 1) !important;
  color: #fff !important;
}

.text_1 {
  color: #333333;
}

.text_2 {
  color: #999999;
}

.dialog_title {
  font-size: 14px;
  font-family: "MicrosoftYaHei";
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
}
</style>
