<template>
  <div class="d1">
    <div class="flex alignFlexEnd justifySpaceBetween">
      <div class="flex">
        <div class="mr56">
          <label class="name">客户名称</label>
          <el-input
            v-model.trim="formInline.name"
            placeholder="请输入客户名称"
          ></el-input>
        </div>
        <div class="mr56">
          <label class="name">机构代码</label>
          <el-input
            v-model.trim="formInline.creditCode"
            placeholder="请输入机构代码"
          ></el-input>
        </div>
      </div>
      <div class="btn-wrap">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </div>
    </div>
    <div class="add" @click="addAccount">
      <i class="el-icon-circle-plus-outline"></i>
      <span>新增</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tableTitle: String,
    tableHearder: Array,
    tableData: Array,
    col: Number,
    loading: Boolean,
  },
  data() {
    return {
      formInline: {
        name: undefined,
        identifier: undefined,
      },
      jumpNameMap: {
        Filing: "FilingAdd",
        Account: "AddAccount",
      },
    };
  },
  methods: {
    addAccount() {
      const routeName = this.$route.name;
      this.jumpNameMap[routeName] &&
        this.$router.push({ name: this.jumpNameMap[routeName] });
    },
    onSubmit() {
      this.$emit("formInline", this.formInline);
    },
    onReset() {
      this.formInline = {
        name: undefined,
        creditCode: undefined,
      };
      this.$emit("formInline", this.formInline);
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.d1 {
  padding: 32px 32px 24px;
}
.mr56 {
  margin-right: 56px;
  &:last-child {
    margin-right: 0;
  }
  .name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616875;
    display: inline-block;
    margin-bottom: 8px;
  }
  /deep/ .el-input__inner {
    /* border: 1px solid #8F96A3; */
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    opacity: 0.6;
    &::placeholder {
      color: #8F96A3;
    }
  }
}
.add {
  padding: 10px 16px;
  margin-top: 32px;
  gap: 8px;
  width: 84px;
  height: 40px;
  background: #ff9428;
  border-radius: 4px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.btn-wrap {
  font-size: 0;
  .el-button {
    padding: 10px 24px;
    line-height: 18px;
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
}

</style>
