<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      :before-close="onClickClose"
      :close-on-click-modal="false"
      :title="title"
      :width="width"
      class="panel-dialog"
    >
      <el-form
        :model="formModel"
        :rules="rules"
        ref="formModel"
        :inline-message="true"
      >
        <ul>
          <template v-for="(item, index) in detailList">
            <li
              v-if="isNeed(item['propName'])"
              class="li1 flex alignCenter"
              :key="`detailList${index}`"
            >
              <label>{{ item.name }}</label>
              <el-form-item
                v-if="item.type == 'select'"
                :prop="item['propName']"
                :style="{ marginBottom: '0px' }"
              >
                <el-select
                  class="w200"
                  size="small"
                  v-model="formModel[item['propName']]"
                  :placeholder="`请选择${item.name}`"
                  :style="{ width: '280px !important' }"
                  @change="changeSel(item)"
                >
                  <el-option
                    v-for="index in Object.keys(item.options)"
                    :key="index"
                    :label="item.options[index]"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <span v-else>{{ item.value }}</span>
            </li>
          </template>
        </ul>
      </el-form>
      <div class="footer" slot="footer">
        <el-button plain size="small" class="btn" @click="onClickClose"
          >关闭</el-button
        >
        <el-button
          plain
          size="small"
          class="btn color_1"
          @click="submit('formModel')"
          >保存修改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ACCOUNT_SCHEMA } from "@/util/constant";

export default {
  watch: {
    item(newVal) {
      if (newVal) {
        this.setDialogInfo(newVal);
      }
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      detailList: [],
      isShow: false,
      title: "",
      width: "",
      formModel: {
        chargingModel: undefined,
        billingScheme: "0",
        expireDate: undefined,
      },
      rules: {
        chargingModel: { required: true, message: "请选择账户方案" },
        billingScheme: { required: true, message: "请选择方案规格" },
        expireDate: { required: true, message: "请选择方案有效期" },
      },
    };
  },
  computed: {
    sourceType() {
      return this.item.type;
    },
    schemeType() {
      return ACCOUNT_SCHEMA["type"];
    },
    schemeSpec() {
      return ACCOUNT_SCHEMA["spec"];
    },
    schemeExpire() {
      return this.formModel.chargingModel == 3
        ? {
            0: "不限",
          }
        : ACCOUNT_SCHEMA["expire"];
    },
    schemeSpecLimit() {
      return ACCOUNT_SCHEMA["specLimit"];
    },
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.sourceType === "accountCustomer") {
            const billingScheme =
              this.schemeSpecLimit[this.formModel.billingScheme];
            const usedTimes = this.item.usedTimes;
            if (billingScheme > 0 && billingScheme < usedTimes) {
              this.$message.error({
                message: "使用次数不得超过方案规格上限，无法完成变更!",
              });
              return;
            }
            this.$emit(
              "open-confirm-dialog",
              Object.assign(this.formModel, {
                usableTimes: this.item.usableTimes,
                usedTimes,
                shopId: this.item.shopId,
              })
            );
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onClickClose() {
      this.isShow = false;
    },
    setDialogInfo(data) {
      this.isShow = data.isShow;
      if (this.sourceType === "accountCustomer") {
        this.title = "方案管理";
        this.width = "560px";
        this.setCustomerInfo(data);
      }
    },
    setCustomerInfo(data) {
      this.formModel.chargingModel = String(data.chargingModel);
      this.formModel.billingScheme =
        data.billingScheme >= 0 ? String(data.billingScheme) : undefined;
      this.formModel.expireDate = String(data.expireDate || 0);
      this.detailList = [
        { name: "客户编号", value: data.numid },
        { name: "客户名称", value: data.shopName },
        { name: "渠道编号", value: data.appId },
        {
          name: "账户方案",
          type: "select",
          propName: "chargingModel",
          options: this.schemeType,
        },
        {
          name: "方案规格",
          type: "select",
          propName: "billingScheme",
          options: this.schemeSpec[3],
        },
        {
          name: "方案有效期",
          type: "select",
          propName: "expireDate",
          options: this.schemeExpire,
        },
        {
          name: "账户余额",
          value:
            this.formModel.chargingModel == 2
              ? "-"
              : Number(data.usableTimes || 0).toLocaleString(),
        },
        {
          name: "累计使用",
          value:
            this.formModel.chargingModel == 2
              ? "-"
              : Number(data.usedTimes || 0).toLocaleString(),
        },
      ];
    },
    isNeed(propName) {
      return !(
        this.sourceType === "accountCustomer" &&
        this.formModel.chargingModel == 2 &&
        propName == "billingScheme"
      );
    },
    changeSel(data) {
      const propName = data.propName;
      if (propName == "chargingModel") {
        this.detailList.map((item) => {
          if (item.propName == "expireDate") {
            item.options = this.schemeExpire;
          }
          if (item.name == "账户余额") {
            item.value =
              this.formModel.chargingModel == 2
                ? "-"
                : Number(this.item.usableTimes || 0).toLocaleString();
          }
          if (item.name == "累计使用") {
            item.value =
              this.formModel.chargingModel == 2
                ? "-"
                : Number(this.item.usedTimes || 0).toLocaleString();
          }
          return item;
        });
        this.formModel.billingScheme = "0";
        this.formModel.expireDate = "0";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_baseDialog.scss";

.w200 {
  width: 200px !important;
  height: 32px !important;
}
.li1 {
  height: 48px;
  border-bottom: 1px solid #e3e5e8;
  label {
    width: 120px;
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333a47;
  }
  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616875;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.color_1 {
  background: rgba(37, 137, 255, 1) !important;
  color: #fff !important;
}

.text_1 {
  color: #333333;
}

.text_2 {
  color: #999999;
}

.dialog_title {
  font-size: 14px;
  font-family: "MicrosoftYaHei";
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
}
</style>
